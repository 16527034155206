//React
import React, { useEffect, useState } from "react";

//Library components
import { Select } from "antd";

//Api
import axios from "../api/axios";

const SelectSearch = (props) => {
    //Select Search
    const [data, setData] = useState(props.initialData);

    const handleSelectSearchChange = (selected) => {
        props.setValue(selected);
        props.onChange(selected);
    };

    const handleFetchSearchData = async (value, searchSetData) => {
        await axios.get(props.endpoint).then((response) => {
            const data = response.data.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );

            searchSetData(data);
        });
    };
    const handleSearch = (search) => {
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(handleFetchSearchData(search, setData));
            }, 300);
        });
    };

    useEffect(() => {
        // Atualize as opções quando a propriedade initialData mudar
        if (props.initialData) {
            setData(props.initialData);
        }
    }, [props.initialData]);

    return (
        <Select
            showSearch
            value={props.value}
            placeholder={props.placeholder}
            defaultActiveFirstOption={false}
            filterOption={false}
            mode={props.mode}
            onSearch={handleSearch}
            onChange={handleSelectSearchChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
                value: d.id,
                label: d.name,
            }))}
        />
    );
};

export default SelectSearch;
