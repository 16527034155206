//React
import React, { useState, useEffect } from "react";

//Components
import SelectSearch from "./SelectSearch";
import TextEditor from "./TextEditor";
import ImgCrop from "antd-img-crop";

//Library components
import {
    Button,
    Form,
    Input,
    DatePicker,
    Select,
    TimePicker,
    InputNumber,
    Radio,
    Upload,
    message,
} from "antd";

import IntlCurrencyInput from "react-intl-currency-input";

import axios from "../api/axios";
import pattern from "../api/pattern";

//Settings
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pt_BR";
import { MaskedInput } from "antd-mask-input";

//Variables
const { endpoints } = pattern;
const uploadLocale = {
    uploading: "Carregando...",
    removeFile: "Remover arquivo",
    downloadFile: "Baixar arquivo",
    uploadError: "Erro no upload",
    previewFile: "Pré-visualizar arquivo",
};

const FormComponent = (props) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [disabledTimePicker, setDisabledTimePicker] = useState(true);

    const [selectSearchValue, setSelectSearchValue] = useState();

    const [formChanged, setFormChanged] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});

    const [availableTimes, setAvailableTimes] = useState([]);

    const [fileList, setFileList] = useState(props.initialFileList || []);

    const handleFetchAvailableTimes = async (date) => {
        const response = await axios.post(
            endpoints.schedules.availableTimes,
            { date },
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        setAvailableTimes(response.data);
    };
    const handleDisabledDateFuture = (current) => {
        return current && current > dayjs().endOf("day");
    };
    const handleDisabledDatePast = (current) => {
        return current && current < dayjs().startOf("day");
    };

    const handleDisabledTimePicker = () => {
        const dateValue = props.form.getFieldValue("date");

        if (dateValue === undefined || dateValue === null) {
            return setDisabledTimePicker(true);
        }

        setDisabledTimePicker(false);
    };

    const handleDatePickerChange = (date) => {
        const teste = dayjs(date).format("YYYY-MM-DD");
        handleFetchAvailableTimes(teste);
        props.form.resetFields(["timerange"]);
        setSelectedDate(date);
        handleDisabledTimePicker();
    };

    const handleDisabledTime = (_) => {
        if (selectedDate) {
            const selectedDateStart = dayjs(selectedDate).startOf("day");
            const isSameSelectedDate = selectedDateStart.isSame(_, "day");

            if (isSameSelectedDate) {
                const currentHour = dayjs().hour();
                return {
                    disabledHours: () =>
                        Array.from({ length: currentHour }, (_, i) => i),
                    disabledMinutes: (selectedHour) =>
                        selectedHour === currentHour
                            ? Array.from(
                                  { length: dayjs().minute() },
                                  (_, i) => i
                              )
                            : [],
                };
            }
        }
        return {
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23],
            disabledMinutes: () => [],
        };
    };

    const handleFormChange = (changedValues, allValues) => {
        const requiredFields = props.formItens
            .filter((item) => {
                // Filtrar apenas os campos que são obrigatórios
                return (
                    item.formItemRules &&
                    item.formItemRules.some((rule) => rule.required)
                );
            })
            .map((item) => item.formItemName);

        const allFieldsNotEmpty = requiredFields.every((fieldName) => {
            const fieldValue = allValues[fieldName];

            return (
                fieldValue !== undefined &&
                fieldValue !== "" &&
                fieldValue !== null
            );
        });

        const isFormChanged = Object.keys(allValues).some((fieldName) => {
            const originalValue = initialFormValues[fieldName];
            const changedValue = allValues[fieldName];

            console.log("Original: ", originalValue);
            console.log("Mudou: ", changedValue);
            if (dayjs(changedValue).isValid()) {
                const originalDate = dayjs(originalValue);
                const changedDate = dayjs(changedValue);

                return !originalDate.isSame(changedDate);
            }
            if (Array.isArray(changedValue)) {
                return changedValue.some((value, indice) => {
                    if (dayjs(value).isValid()) {
                        const originalDate = dayjs(originalValue[indice]);
                        const changedDate = dayjs(value);

                        return !originalDate.isSame(changedDate);
                    } else {
                        return value !== originalValue[indice];
                    }
                });
            } else {
                /*if (originalValue === undefined) {
                return (
                    changedValue === undefined &&
                    changedValue === "" &&
                    changedValue === null
                );
            }*/
                // Se não é uma data, comparar diretamente
                return originalValue !== changedValue;
            }
        });

        setFormChanged(isFormChanged && allFieldsNotEmpty);
    };

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const handleCurrencyInput = (event, value, maskedValue) => {
        props.form.setFieldsValue({
            ["value"]: value,
        });
    };

    const validationFile = (file) => {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;

        const accept = isJpgOrPng && isLt2M;
        return accept;
    };

    const beforeCrop = (file) => {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Você só pode fazer upload de arquivos JPG/PNG!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("A imagem deve ter menos de 2MB!");
        }

        const accept = isJpgOrPng && isLt2M;

        return accept;
    };

    const beforeUpload = async (file) => {
        const newFile = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: URL.createObjectURL(file),
            originFileObj: file,
        };

        if (validationFile(file)) {
            setFileList([newFile]);
        }
        return false;
    };

    const handleRemove = async (file) => {
        setFileList(fileList.filter((item) => item.uid !== file.uid));
        if (props.initialFileList && props.initialFileList[0].url) {
            try {
                const fileName = file.url.split("/").pop();
                await axios.delete(
                    endpoints.patients.deleteImage.replace(
                        "${}",
                        props.patientId
                    ),
                    {
                        data: { fileName }, // Envia o nome do arquivo a ser deletado
                    }
                );

                message.success("Sucesso ao remover a imagem.");
            } catch (error) {
                console.error("Erro ao remover a imagem:", error);
                message.error("Erro ao remover a imagem.");
            }
        }
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    useEffect(() => {
        if (props.initialFileList) {
            setFileList(props.initialFileList);
        }
    }, [props.initialFileList]);

    useEffect(() => {
        setFormChanged(false);
        handleDisabledTimePicker();
        if (!props.openDrawer) {
            setFileList([]);
        }
    }, [props.openDrawer]);

    useEffect(() => {
        // Armazene os valores iniciais quando o componente montar
        setInitialFormValues(props.form.getFieldsValue());
    }, [props.form]);

    useEffect(() => {
        if (props.onFileListChange) {
            props.onFileListChange(fileList);
        }
    }, [fileList]);

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "input":
                return (
                    <Input
                        placeholder={form.formPlaceholder}
                        type={form.formInputType}
                    />
                );
            case "inputNumber":
                return (
                    <InputNumber
                        formatter={form.formInputFormatter}
                        parser={form.formInputParser}
                        style={{ width: "100%" }}
                        addonBefore={form.formInputAddonBefore}
                        min={form.formNumberMin}
                        max={form.formNumberMax}
                    />
                );

            case "inputCurrency":
                return (
                    <IntlCurrencyInput
                        id="currencyInput"
                        currency="BRL"
                        max={10000000}
                        onChange={handleCurrencyInput}
                        config={currencyConfig}
                    />
                );
            case "password":
                return (
                    <Input.Password
                        placeholder={form.formPlaceholder}
                        autoComplete="off"
                    />
                );
            case "searchInput":
                return (
                    <SelectSearch
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        value={selectSearchValue}
                        setValue={setSelectSearchValue}
                        main={form.formSelectMain}
                        initialData={
                            props.selectSearchProps.data[form.formSelectEntity]
                        }
                        endpoint={
                            props.selectSearchProps.endpoint[
                                form.formSelectEntity
                            ]
                        }
                        onChange={(value) =>
                            props.form.setFieldsValue({
                                [form.formItemName]: value,
                            })
                        }
                    />
                );
            case "mask":
                return <MaskedInput mask={form.formInputMask} />;
            case "date":
                return (
                    <DatePicker
                        style={{ width: "100%" }}
                        placeholder={form.formPlaceholder}
                        locale={locale}
                        format={"DD/MM/YYYY"}
                        onChange={(value) => {
                            if (form.formDisabledDate === "past") {
                                handleDatePickerChange(value);
                            }
                        }}
                        disabledDate={
                            form.formDisabledDate === "future"
                                ? handleDisabledDateFuture
                                : handleDisabledDatePast
                        }
                    />
                );
            case "timePicker":
                return (
                    <TimePicker.RangePicker
                        style={{ width: "100%" }}
                        placeholder={["Horário início", "Horário fim"]}
                        format="HH:mm"
                        disabled={
                            form.formTimeRangeOnly ? false : disabledTimePicker
                        }
                        minuteStep={20}
                        disabledTime={handleDisabledTime}
                    />
                );
            case "radio":
                return (
                    <Radio.Group>
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                    </Radio.Group>
                );
            case "select":
                return (
                    <Select
                        style={{ width: "100%" }}
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        options={form.formSelectOptions}
                    />
                );
            case "textArea":
                return (
                    <TextEditor
                        form={props.form}
                        formItemName={form.formItemName}
                        readOnly={false}
                    />
                );
            case "uploadAvatar":
                return (
                    <ImgCrop
                        rotationSlider
                        modalTitle="Editar imagem"
                        modalOk="Ok"
                        modalCancel="Cancelar"
                        beforeCrop={beforeCrop}
                    >
                        <Upload
                            listType="picture-card"
                            locale={uploadLocale}
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            accept="image/png, image/jpeg"
                            maxCount={1}
                            onPreview={onPreview}
                            onRemove={handleRemove}
                        >
                            {fileList.length < 1 && "+ Upload"}
                        </Upload>
                    </ImgCrop>
                );
            default:
                return null;
        }
    };

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
            //onValuesChange={handleFormChange}
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        dependencies={form.formItemDependencies}
                        rules={form.formItemRules}
                        initialValue={form.formItemInitialValue}
                        label={form.formItemLabel}
                        tooltip={form.formItemTooltip}
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                </React.Fragment>
            ))}

            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    loading={props.loading}
                    //disabled={!formChanged}
                >
                    {props.buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};
export default FormComponent;
