//React
import React, { useState, useEffect } from "react";

//Library components
import { Button, Form, Input, TimePicker } from "antd";
import { MaskedInput } from "antd-mask-input";

//Settings
import dayjs from "dayjs";

const FormAccount = (props) => {
    const [formChanged, setFormChanged] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});

    const handleFormChange = (changedValues, allValues) => {
        const isFormChanged = Object.keys(allValues).some((fieldName) => {
            const originalValue = initialFormValues[fieldName];
            const changedValue = allValues[fieldName];

            if (Array.isArray(changedValue)) {
                return changedValue.some((value, indice) => {
                    if (dayjs(value).isValid()) {
                        const originalDate = dayjs(originalValue[indice]);
                        const changedDate = dayjs(value);

                        return !originalDate.isSame(changedDate);
                    } else {
                        return value !== originalValue[indice];
                    }
                });
            } else {
                return originalValue !== changedValue;
            }
        });

        setFormChanged(isFormChanged);
    };

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "input":
                return (
                    <Input
                        placeholder={form.formPlaceholder}
                        disabled={props.buttonState.disabled}
                        type={form.formInputType}
                    />
                );
            case "mask":
                return (
                    <MaskedInput
                        mask={form.formInputMask}
                        disabled={props.buttonState.disabled}
                    />
                );
            case "timePicker":
                return (
                    <TimePicker.RangePicker
                        style={{ width: "100%" }}
                        placeholder={["Horário início", "Horário fim"]}
                        format="HH:mm"
                        minuteStep={30}
                        disabled={props.buttonState.disabled}
                    />
                );
        }
    };

    useEffect(() => {
        setInitialFormValues(props.initialValue);
    }, [props.initialValue]);

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
            onValuesChange={handleFormChange}
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        label={form.formItemLabel}
                        rules={form.formItemRules}
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                </React.Fragment>
            ))}
            <Form.Item>
                {props.buttonState.disabled ? (
                    <Button
                        style={{ width: "100%" }}
                        onClick={() => props.buttonState.setDisabled(false)}
                        type="primary"
                    >
                        {props.buttonPrimaryText}
                    </Button>
                ) : (
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        disabled={!formChanged}
                    >
                        {props.buttonSecondText}
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};
export default FormAccount;
