import React, { createContext, useState, useContext, useEffect } from "react";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

// Criar um contexto para armazenar dados de autenticação
const AuthContext = createContext();

//Variables
const { endpoints } = pattern;

// Um provedor de contexto que armazena os dados de autenticação
export const AuthProvider = ({ children }) => {
    const [authChecked, setAuthChecked] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState();
    const [role, setRole] = useState();
    const [userName, setUserName] = useState();
    const [account, setAccount] = useState();
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 500);
    };

    const checkAuth = async () => {
        try {
            const response = await axios.get(endpoints.auth.checkAuth);
            const {
                data: { authenticated, id, name, role },
            } = response;

            setIsAuthenticated(authenticated);
            setUserId(id);
            setUserName(name);
            setRole(role);
            setAuthChecked(true);
            try {
                const response = await axios.get(endpoints.account.myAccount);
                setAccount(response.data);
            } catch (error) {
                console.error("Erro ao obter informações da conta:", error);
            }
        } catch (error) {
            console.error("Erro ao verificar autenticação:", error);
            setIsAuthenticated(false);
            setAuthChecked(true);
        }
    };

    useEffect(() => {
        checkAuth();
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authChecked,
                isAuthenticated,
                account,
                setAccount,
                userId,
                userName,
                role,

                isMobile,
                checkAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// Um hook personalizado para acessar o contexto de autenticação em qualquer componente
export const useAuth = () => useContext(AuthContext);
