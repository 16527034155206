//React
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

//Components
import FormComponent from "../components/FormComponent";
import FormBudget from "../components/FormBudget";
import FormSchedule from "../components/FormSchedule";

//Icons
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
    MdDashboard,
    MdAccountBalance,
    MdContentPasteSearch,
} from "react-icons/md";
import {
    FaTooth,
    FaList,
    FaUser,
    FaSignOutAlt,
    FaCalendar,
    FaTeethOpen,
    FaCreditCard,
    FaUsers,
    FaClipboardList,
    FaBriefcase,
} from "react-icons/fa";
import { FaCalendarPlus, FaGear, FaArrowUpRightDots } from "react-icons/fa6";
import { GrAdd } from "react-icons/gr";

//Library components
import {
    Layout,
    Menu,
    Button,
    theme,
    FloatButton,
    Drawer,
    Form,
    Tooltip,
    message,
} from "antd";

//Utils
import formattedDate from "../assets/utils/formattedDate";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms } = pattern;
const { Header, Sider, Content } = Layout;

const App = (props) => {
    const { userId, role, checkAuth, isMobile } = useAuth();
    const navigate = useNavigate();
    const currentLocation = useLocation();
    const { pathname } = currentLocation;

    const [collapsed, setCollapsed] = useState(true);

    const [open, setOpen] = useState(false);
    const [openBudget, setOpenBudget] = useState(false);
    const [openSchedule, setOpenSchedule] = useState(false);

    const [dataActiveDentists, setDataActiveDentists] = useState([]);
    const [dataPatients, setDataPatients] = useState([]);
    const [dataTreatments, setDataTreatments] = useState([]);

    const [dataActiveConvenios, setDataActiveConvenios] = useState([]);
    const [dataActiveCategories, setDataActiveCategories] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [form] = Form.useForm();
    const [formSchedule] = Form.useForm();
    const [formBudget] = Form.useForm();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const fetchDataPatients = async () => {
        try {
            const response = await axios.get(endpoints.patients.index);
            setDataPatients(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveDentists = async () => {
        try {
            const response = await axios.get(
                endpoints.users.indexActiveDentists
            );
            setDataActiveDentists(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataTreatments = async () => {
        try {
            const response = await axios.get(endpoints.treatments.index);
            setDataTreatments(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };
    const fetchDataActiveCategories = async () => {
        try {
            const response = await axios.get(endpoints.categories.indexCreate);
            setDataActiveCategories(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchDataActiveDentists();
        fetchDataPatients();
        fetchDataActiveCategories();
    }, [openSchedule]);

    useEffect(() => {
        fetchDataPatients();
        fetchDataTreatments();
    }, [openBudget]);

    const fetchDataActiveConvenios = async () => {
        try {
            const response = await axios.get(endpoints.convenios.indexCreate);
            setDataActiveConvenios(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchDataActiveConvenios();
    }, [open]);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const handleRemoveImagePatient = async (file) => {
        console.log("teste");
        try {
            const fileName = file.response.url.split("/").pop();
            await axios.delete(endpoints.patients.deleteImage, {
                data: { fileName }, // Envia o nome do arquivo a ser deletado
            });
        } catch (error) {
            console.error("Erro ao remover a imagem:", error);
            message.error("Erro ao remover a imagem.");
        }
    };

    const handleUploadImagePatient = async (file) => {
        const formData = new FormData();
        formData.append("photo", file);

        try {
            const response = await axios.post(
                endpoints.patients.uploadImage,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response.data.url;
        } catch (error) {
            console.error("Erro ao fazer upload da imagem:", error);
            message.error("Erro ao fazer upload da imagem.");
        }
    };

    const onFinish = async (values) => {
        try {
            if (fileList.length > 0) {
                const file = fileList[0].originFileObj;
                const imageUrl = await handleUploadImagePatient(file);
                values.photo_url = imageUrl; // Adiciona a URL da imagem nos valores do formulário
            }

            await axios.post(endpoints.patients.create, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            onClose();

            message.success("Paciente cadastrado com sucesso!");

            if (pathname === "/patients") {
                props.setFetchDataPatients();
            }
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
            await handleRemoveImagePatient(values.photo_url);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const showDrawerSchedule = () => {
        setOpenSchedule(true);
    };
    const onCloseSchedule = () => {
        setOpenSchedule(false);
        formSchedule.resetFields();
    };
    const onFinishSchedule = async (values) => {
        const formattedData = formattedDate(values, role, userId);

        try {
            await axios.post(endpoints.schedules.create, formattedData, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            onCloseSchedule();

            message.success("Agendamento cadastrado com sucesso!");

            if (pathname === "/schedules" || pathname === "/dashboard") {
                props.setFetchDataSchedules();
            }
        } catch (error) {
            if (error.response.data.available === false) {
                return message.error("Horário não disponível!");
            }

            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailedSchedule = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const showDrawerBudget = () => {
        setOpenBudget(true);
    };
    const onCloseBudget = () => {
        setOpenBudget(false);
        formBudget.resetFields();
    };

    const onFinishBudget = async (values) => {
        try {
            await axios.post(endpoints.budgets.create, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            onCloseBudget();

            message.success("Orçamento cadastrado com sucesso!");

            if (pathname === "/budgets") {
                props.setFetchDataBudgets();
            }
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailedBudget = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const logout = async () => {
        try {
            await axios.get(endpoints.auth.logout);
            localStorage.removeItem("token");
            checkAuth();
        } catch (error) {
            console.log(error);
        }
    };

    const menuItems = [
        {
            key: "1",
            icon: <MdDashboard />,
            label: isMobile ? "" : "Dashboard",
            onClick: () => {
                navigate("/dashboard");
            },
        },
        {
            key: "2",
            icon: <FaTooth />,
            label: isMobile ? "" : "Pacientes",
            onClick: () => {
                navigate("/patients");
            },
        },
        {
            key: "3",
            icon: <FaCalendar />,
            label: isMobile ? "" : "Agendamentos",
            onClick: () => {
                navigate("/schedules");
            },
        },
        role !== 3
            ? {
                  key: "11",
                  icon: <FaGear />,
                  label: isMobile ? "" : "Gerenciamento",
                  children: [
                      {
                          key: "14",
                          icon: <FaCreditCard />,
                          label: "Convênios",
                          onClick: () => {
                              navigate("/convenios");
                          },
                      },
                      {
                          key: "15",
                          icon: <FaList />,
                          label: "Categorias",
                          onClick: () => {
                              navigate("/categories");
                          },
                      },
                      {
                          key: "12",
                          icon: <FaTeethOpen />,
                          label: "Tratamentos",
                          onClick: () => {
                              navigate("/treatments");
                          },
                      },

                      {
                          key: "16",
                          icon: <FaClipboardList />,
                          label: "Anamnese",
                          onClick: () => {
                              navigate("/anamnese-questions");
                          },
                      },
                  ],
              }
            : "",
        role === 1 || role === 4
            ? {
                  key: "5",
                  icon: <MdAccountBalance />,
                  label: isMobile ? "" : "Conta",
                  children: [
                      {
                          key: "42",
                          icon: <FaBriefcase />,
                          label: "Minha conta",
                          onClick: () => {
                              navigate("/account");
                          },
                      },
                      {
                          key: "6",
                          icon: <FaUser />,
                          label: "Meu perfil",
                          onClick: () => {
                              navigate("/profile");
                          },
                      },
                      {
                          key: "20",
                          icon: <FaUsers />,
                          label: "Usuários",
                          onClick: () => {
                              navigate("/users");
                          },
                      },
                      {
                          key: "60",
                          icon: <MdContentPasteSearch />,
                          label: "Logs",
                          onClick: () => {
                              navigate("/logs");
                          },
                      },
                      {
                          key: "7",
                          icon: <FaSignOutAlt />,
                          label: "Sair",
                          onClick: () => {
                              logout();
                          },
                      },
                  ],
              }
            : "",
        role !== 1 && role !== 4
            ? {
                  key: "7",
                  icon: <FaSignOutAlt />,
                  label: isMobile ? "" : "Sair",
                  onClick: () => {
                      logout();
                  },
              }
            : "",
    ];

    if (role === 1 || role === 4) {
        menuItems.pop();
        menuItems.push();
    }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            {isMobile ? (
                <></>
            ) : (
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="demo-logo-vertical" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                        }}
                        defaultSelectedKeys={[props.tabkey]}
                        items={menuItems}
                    />
                </Sider>
            )}
            <Layout>
                <Header
                    style={
                        isMobile
                            ? {
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  padding: "0px",
                              }
                            : {
                                  padding: 0,
                                  background: colorBgContainer,
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                  width: "100%",
                              }
                    }
                >
                    {isMobile ? (
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            style={{
                                flex: 1,
                                display: "flex",
                            }}
                            disabledOverflow={true}
                            defaultSelectedKeys={[props.tabkey]}
                            items={menuItems}
                        />
                    ) : (
                        <Button
                            type="text"
                            icon={
                                collapsed ? (
                                    <MenuUnfoldOutlined />
                                ) : (
                                    <MenuFoldOutlined />
                                )
                            }
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: "16px",
                                width: 64,
                                height: 64,
                            }}
                        />
                    )}
                </Header>
                <Content
                    style={{
                        margin: "24px 16px",
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                    }}
                >
                    {props.content}
                </Content>
                <FloatButton.Group
                    trigger="click"
                    type="primary"
                    style={{ right: 20, bottom: 30 }}
                    icon={<GrAdd />}
                >
                    <Tooltip placement="leftTop" title="+ Evolução">
                        <FloatButton
                            icon={<FaArrowUpRightDots />}
                            onClick={showDrawerBudget}
                        />
                    </Tooltip>
                    <Tooltip placement="leftTop" title="+ Agendar">
                        <FloatButton
                            icon={<FaCalendarPlus />}
                            onClick={showDrawerSchedule}
                        />
                    </Tooltip>
                    <Tooltip placement="leftTop" title="+ Paciente">
                        <FloatButton icon={<FaTooth />} onClick={showDrawer} />
                    </Tooltip>
                </FloatButton.Group>
                <Drawer
                    title="+ Cadastrar Paciente"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key="createPatient"
                >
                    <FormComponent
                        name="createPatient"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        formItens={forms.patients.single}
                        onFileListChange={setFileList}
                        selectSearchProps={{
                            data: {
                                convenios: dataActiveConvenios,
                            },
                            endpoint: {
                                convenios: endpoints.convenios.indexCreate,
                            },
                        }}
                        openDrawer={open}
                        buttonText="Cadastrar"
                    />
                </Drawer>

                <Drawer
                    title="+ Agendar"
                    placement="right"
                    closable={false}
                    onClose={onCloseSchedule}
                    open={openSchedule}
                    key="createSchedule"
                >
                    <FormSchedule
                        name="createSchedule"
                        form={formSchedule}
                        onFinish={onFinishSchedule}
                        onFinishFailed={onFinishFailedSchedule}
                        formItens={
                            role !== 2
                                ? forms.schedules.createWithDentist
                                : forms.schedules.createWithoutDentist
                        }
                        selectSearchProps={{
                            data: {
                                patients: dataPatients,
                                categories: dataActiveCategories,
                                dentists: dataActiveDentists,
                            },
                            endpoint: {
                                patients: endpoints.patients.index,
                                categories: endpoints.categories.indexCreate,
                                dentists: endpoints.users.indexActiveDentists,
                            },
                        }}
                        openDrawer={openSchedule}
                        buttonText="Agendar"
                    />
                </Drawer>
                <Drawer
                    title="+ Orçamento"
                    placement="right"
                    closable={false}
                    onClose={onCloseBudget}
                    open={openBudget}
                    key="createBudget"
                >
                    <FormBudget
                        name="createBudget"
                        form={formBudget}
                        onFinish={onFinishBudget}
                        onFinishFailed={onFinishFailedBudget}
                        formItens={forms.budgets.single}
                        selectSearchProps={{
                            data: {
                                patients: dataPatients,
                                treatments: dataTreatments,
                            },
                            endpoint: {
                                patients: endpoints.patients.index,
                                treatments: endpoints.treatments.index,
                            },
                        }}
                        openDrawer={openBudget}
                        buttonText="Gerar orçamento"
                    />
                </Drawer>
            </Layout>
        </Layout>
    );
};
export default App;
