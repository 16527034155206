//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import FormAccount from "../components/FormAccount";

//Library components
import { Form, message } from "antd";

//Settings
import dayjs from "dayjs";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms } = pattern;

const Account = () => {
    const { setAccount } = useAuth();
    const [data, setData] = useState({});

    const [disabled, setDisabled] = useState(true);
    const [form] = Form.useForm();

    const convertTimeToDayjs = (timeString) => {
        const [hours, minutes] = timeString.split(":").map(Number);
        const dateTime = dayjs().hour(hours).minute(minutes).second(0);

        return dateTime;
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.account.myAccount);

            const account = response.data;

            setAccount(account);

            account.timerange = [
                convertTimeToDayjs(account.opening_start),
                convertTimeToDayjs(account.opening_end),
            ];

            setData(account);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    const formattedTime = (values) => {
        const openingStart = `${dayjs(values.timerange[0]).format("HH:mm")}:00`;
        const openingEnd = `${dayjs(values.timerange[1]).format("HH:mm")}:00`;
        return {
            name: values.name,
            cnpj: values.cnpj,
            opening_start: openingStart,
            opening_end: openingEnd,
        };
    };

    const onFinish = async (values) => {
        const formattedData = formattedTime(values);

        try {
            await axios.post(endpoints.account.update, formattedData, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchData();
            setDisabled(true);
            message.success("Conta atualizada com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const FormAccountMemo = React.memo(FormAccount);

    const content = (
        <FormAccountMemo
            name="editAccount"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            formItens={forms.account}
            initialValue={data}
            buttonState={{
                disabled: disabled,
                setDisabled: setDisabled,
            }}
            buttonPrimaryText="Editar"
            buttonSecondText="Salvar alterações"
        />
    );
    return <Layout tabkey="42" openkey="5" content={content} />;
};

export default Account;
