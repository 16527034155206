//React
import React, { useState, useEffect, useRef } from "react";

//Components
import SelectSearch from "./SelectSearch";

//Library components
import { Button, Form, Select, InputNumber, Flex, List, Statistic } from "antd";
import IntlCurrencyInput from "react-intl-currency-input";

import pattern from "../api/pattern";
const { localeTable } = pattern;

const FormBudget = (props) => {
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [finalValue, setFinalValue] = useState();

    const [selectSearchValue, setSelectSearchValue] = useState();
    const [treatmentsSumValue, setTreatmentsSumValue] = useState(0);
    const [typeDiscount, setTypeDiscount] = useState("%");
    const [discountValue, setDiscountValue] = useState(0);

    const getTreatmentsSumValue = (changedValue, allValues) => {
        const treatmentsIds = allValues["treatments_ids"];
        const discountVal = allValues["discount"].value;

        if (Array.isArray(treatmentsIds)) {
            const treatments = props.selectSearchProps.data.treatments
                .filter((treatment) => {
                    return (
                        treatmentsIds.includes(treatment.id) &&
                        !selectedTreatments.some((dt) => dt.id === treatment.id)
                    );
                })
                .map((treatment) => {
                    return { ...treatment, original_value: treatment.value };
                });

            // Tratamentos a serem removidos (que não estão em treatmentsIds)
            const updatedDataTreatments = selectedTreatments.filter(
                (treatment) => {
                    return treatmentsIds.includes(treatment.id);
                }
            );

            // Adicionar novos tratamentos
            setSelectedTreatments([...updatedDataTreatments, ...treatments]);

            const sumTreatmentsValue = treatments.reduce((sum, item) => {
                return sum + item.value;
            }, 0);

            setTreatmentsSumValue(sumTreatmentsValue);
        }

        if (discountVal !== undefined) {
            setDiscountValue(discountVal);
        }
    };

    const handleCurrencyInput = (event, value, maskedValue) => {
        props.form.setFieldsValue({
            ["final_value"]: value,
        });
    };

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const countRender = useRef(0);

    useEffect(() => {
        if (countRender.current < 2) {
            countRender.current += 1;
            return;
        }

        if (typeDiscount === "R$") {
            const finalValue = treatmentsSumValue - discountValue;
            props.form.setFieldsValue({ ["final_value"]: finalValue });
        }
        if (typeDiscount === "%") {
            const finalValue =
                treatmentsSumValue - (discountValue / 100) * treatmentsSumValue;
            props.form.setFieldsValue({ ["final_value"]: finalValue });
        }
    }, [treatmentsSumValue, discountValue, typeDiscount]);

    useEffect(() => {
        if (!props.openDrawer) {
            setTypeDiscount("%");
            setTreatmentsSumValue(0);
            setDiscountValue(0);
        }
    }, [props.openDrawer]);

    useEffect(() => {
        const sumTreatmentsValue = selectedTreatments.reduce((sum, item) => {
            return sum + Number(item.original_value);
        }, 0);

        setTreatmentsSumValue(sumTreatmentsValue);

        if (typeDiscount === "R$") {
            const finalValue = sumTreatmentsValue - discountValue;
            setFinalValue(finalValue);
        }
        if (typeDiscount === "%") {
            const finalValue =
                sumTreatmentsValue - (discountValue / 100) * sumTreatmentsValue;
            setFinalValue(finalValue);
        }
    }, [selectedTreatments]);
    useEffect(() => {
        setSelectedTreatments(props.originalTreatments);
        setDiscountValue(props.discountValue);
    }, [props.openDrawer]);

    const SelectBeforeInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            value: value.value || 0,
            type: value.type,
        });

        const triggerChange = (changedValue) => {
            onChange?.(changedValue);
        };

        const onTypeChange = (val) => {
            setData({ type: val, value: 0 });
            setDiscountValue(0);
            setTypeDiscount(val);
            triggerChange({ value: 0, type: val });
        };

        const onValueChange = (val) => {
            setData({ ...data, value: val });
            triggerChange({ ...data, value: val });
        };

        return (
            <Flex gap="small">
                <Select
                    defaultValue="%"
                    value={data.type}
                    onChange={onTypeChange}
                    options={[
                        {
                            value: "%",
                            label: "%",
                        },
                        {
                            value: "R$",
                            label: "R$",
                        },
                    ]}
                />
                <InputNumber
                    style={{
                        width: "100%",
                    }}
                    onChange={onValueChange}
                    value={data.value}
                    defaultValue={0}
                    min={0}
                    max={typeDiscount === "%" ? 100 : treatmentsSumValue}
                />
            </Flex>
        );
    };

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "searchInput":
                return (
                    <SelectSearch
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        value={selectSearchValue}
                        setValue={setSelectSearchValue}
                        initialData={
                            props.selectSearchProps.data[form.formSelectEntity]
                        }
                        endpoint={props.selectSearchProps.endpoint[
                            form.formSelectEntity
                        ].replace("${}", [
                            props.form.getFieldValue(form.formSelectMain),
                        ])}
                        onChange={(value) => {
                            props.form.setFieldsValue({
                                [form.formItemName]: value,
                            });
                        }}
                    />
                );
            case "selectBeforeInput":
                return <SelectBeforeInput />;

            case "inputCurrency":
                return (
                    <IntlCurrencyInput
                        id="currencyInput"
                        currency="BRL"
                        max={10000000}
                        onChange={handleCurrencyInput}
                        config={currencyConfig}
                    />
                );
        }
    };

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
            onValuesChange={getTreatmentsSumValue}
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        label={form.formItemLabel}
                        rules={form.formItemRules}
                        initialValue={form.formItemInitialValue}
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                </React.Fragment>
            ))}
            <List
                size="small"
                bordered
                locale={localeTable}
                dataSource={selectedTreatments}
                renderItem={(item) => (
                    <List.Item>
                        {item.name} -{" "}
                        {`R$ ${Number(item.original_value).toLocaleString(
                            "pt-BR",
                            {
                                minimumFractionDigits: 2,
                            }
                        )}`}
                    </List.Item>
                )}
            />
            <br />
            <Statistic
                title="Valor final:"
                value={`R$ ${Number(finalValue).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                })}`}
            />
            <br />
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {props.buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};
export default FormBudget;
