import { Routes, Route } from "react-router-dom";
import AuthProtect from "./context/AuthProtect";

import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Profile from "./pages/Profile";
import Users from "./pages/Users";
import Patients from "./pages/Patients";
import Schedules from "./pages/Schedules";
import Treatments from "./pages/Treatments";
import Convenios from "./pages/Convenios";
import Categories from "./pages/Categories";
import AnamneseQuestions from "./pages/AnamneseQuestions";
import Logs from "./pages/Logs";
import Teste from "./pages/Teste";
import NotFound from "./pages/errors/NotFound";

//CSS
import "./App.css";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Auth />} />
            <Route
                path="/account"
                element={
                    <AuthProtect>
                        <Account allowedRoles={[1, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/profile"
                element={
                    <AuthProtect>
                        <Profile allowedRoles={[1, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/users"
                element={
                    <AuthProtect>
                        <Users allowedRoles={[1, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <AuthProtect>
                        <Dashboard />
                    </AuthProtect>
                }
            />
            <Route
                path="/teste"
                element={
                    <AuthProtect>
                        <Teste />
                    </AuthProtect>
                }
            />
            <Route
                path="/patients"
                element={
                    <AuthProtect>
                        <Patients />
                    </AuthProtect>
                }
            />
            <Route
                path="/schedules"
                element={
                    <AuthProtect>
                        <Schedules />
                    </AuthProtect>
                }
            />
            <Route
                path="/treatments"
                element={
                    <AuthProtect>
                        <Treatments allowedRoles={[1, 2, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/convenios"
                element={
                    <AuthProtect>
                        <Convenios allowedRoles={[1, , 2, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/categories"
                element={
                    <AuthProtect>
                        <Categories allowedRoles={[1, 2, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="/anamnese-questions"
                element={
                    <AuthProtect>
                        <AnamneseQuestions allowedRoles={[1, 2, 4]} />
                    </AuthProtect>
                }
            />
            <Route
                path="logs"
                element={
                    <AuthProtect>
                        <Logs allowedRoles={[1, 4]} />
                    </AuthProtect>
                }
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
