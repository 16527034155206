//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import FormComponent from "../components/FormComponent";

//Icons
import { FaEdit } from "react-icons/fa";

//Library components
import {
    Flex,
    message,
    Table,
    Drawer,
    Form,
    Input,
    Button,
    Tag,
    Descriptions,
    Switch,
} from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;
const { Search } = Input;

const Treatments = () => {
    const { isMobile } = useAuth();

    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [dataActiveConvenios, setDataActiveConvenios] = useState([]);
    const [dataActiveCategories, setDataActiveCategories] = useState([]);

    const [dataUpdateConvenios, setDataUpdateConvenios] = useState([]);
    const [dataUpdateCategories, setDataUpdateCategories] = useState([]);

    const [id, setId] = useState(null);

    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.treatments.index);
            setData(response.data);
            setOriginalData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveConvenios = async () => {
        try {
            const response = await axios.get(endpoints.convenios.indexCreate);
            setDataActiveConvenios(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveCategories = async () => {
        try {
            const response = await axios.get(endpoints.categories.indexCreate);
            setDataActiveCategories(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataUpdateConvenios = async (id) => {
        try {
            const response = await axios.get(
                endpoints.treatments.indexUpdateConvenios.replace("${}", id)
            );
            setDataUpdateConvenios(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataUpdateCategories = async (id) => {
        try {
            const response = await axios.get(
                endpoints.treatments.indexUpdateCategories.replace("${}", id)
            );

            setDataUpdateCategories(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchDataActiveConvenios();
        fetchDataActiveCategories();
    }, [open]);

    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const onSearch = (value) => {
        const valueLowerCase = value.toLowerCase();

        const dataSearch = originalData.filter((treatment) => {
            return (
                treatment.name.toLowerCase().includes(valueLowerCase) ||
                treatment.value_formatted
                    .toLowerCase()
                    .includes(valueLowerCase) ||
                treatment.convenio.name
                    .toLowerCase()
                    .includes(valueLowerCase) ||
                treatment.category.name.toLowerCase().includes(valueLowerCase)
            );
        });

        setData(dataSearch);
    };

    const onFinish = async (values) => {
        try {
            await axios.post(endpoints.treatments.create, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchData();
            onClose();
            message.success("Tratamento cadastrado com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
    };

    const showDrawerEdit = (id) => {
        const treatment = {
            ...data.find((treatment) => treatment.id === id),
        };
        formEdit.setFieldsValue(treatment);
        fetchDataUpdateConvenios(id);
        fetchDataUpdateCategories(id);
        setOpenEdit(true);
    };

    const onFinishEdit = async (values, id_only) => {
        try {
            await axios.post(
                endpoints.treatments.update.replace(
                    "${}",
                    id_only ? id_only : id
                ),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            onCloseEdit();
            message.success("Tratamento atualizado com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const columns = !isMobile
        ? [
              {
                  title: "Tratamento",
                  dataIndex: "name",
                  key: "name",
              },
              {
                  title: "Valor",
                  dataIndex: "value_formatted",
                  key: "value_formatted",
              },
              {
                  title: "Convênio",
                  dataIndex: "convenio",
                  key: "convenio",
                  render: (_, { convenio }) => (
                      <Tag key={convenio.id} color={convenio.color}>
                          {convenio.name}
                      </Tag>
                  ),
              },
              {
                  title: "Categoria",
                  dataIndex: "category",
                  key: "category",
                  render: (_, { category }) => (
                      <Tag key={category.id} color={category.color}>
                          {category.name}
                      </Tag>
                  ),
              },
              {
                  title: "Ativo",
                  dataIndex: "active",
                  key: "active",
                  render: (_, { id, active }) => (
                      <>
                          <Switch
                              checked={active}
                              onChange={(value) => {
                                  onFinishEdit({ active: value }, id);
                              }}
                          />
                      </>
                  ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawerEdit(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ]
        : [
              {
                  title: "Tratamento",
                  dataIndex: "name",
                  key: "name",
              },

              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawerEdit(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ];

    const expandedRowRender = (record) => {
        const items = !isMobile
            ? []
            : [
                  {
                      label: "Valor",
                      children: record.value_formatted,
                  },
                  {
                      label: "Convênio",
                      children: (
                          <Tag
                              key={record.convenio.id}
                              color={record.convenio.color}
                          >
                              {record.convenio.name}
                          </Tag>
                      ),
                  },
                  {
                      label: "Categoria",
                      children: (
                          <Tag
                              key={record.category.id}
                              color={record.category.color}
                          >
                              {record.category.name}
                          </Tag>
                      ),
                  },
              ];

        return <Descriptions items={items} />;
    };

    const content = (
        <>
            <Flex
                className="table-header-button-search"
                justify="space-between"
                gap="middle"
                style={{ marginBottom: "24px" }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    + Cadastrar
                </Button>
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                    }}
                />
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                expandable={!isMobile ? false : { expandedRowRender }}
                rowKey={(record) => record.id}
            />
            <Drawer
                title="+ Cadastrar tratamento"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onClose}
                open={open}
                key="createTreatment"
            >
                <FormComponent
                    name="createTreatment"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.treatments.single}
                    selectSearchProps={{
                        data: {
                            convenios: dataActiveConvenios,
                            categories: dataActiveCategories,
                        },
                        endpoint: {
                            convenios: endpoints.convenios.index,
                            categoires: endpoints.categories.index,
                        },
                    }}
                    openDrawer={open}
                    buttonText="Cadastrar"
                />
            </Drawer>
            <Drawer
                title="Editar tratamento"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onCloseEdit}
                open={openEdit}
                key="editTreatment"
            >
                <FormComponent
                    name="editTreatment"
                    form={formEdit}
                    onFinish={onFinishEdit}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.treatments.single}
                    selectSearchProps={{
                        data: {
                            convenios: dataUpdateConvenios,
                            categories: dataUpdateCategories,
                        },
                        endpoint: {
                            convenios:
                                endpoints.treatments.indexUpdateConvenios.replace(
                                    "${}",
                                    id
                                ),
                            categoires:
                                endpoints.treatments.indexUpdateCategories.replace(
                                    "${}",
                                    id
                                ),
                        },
                    }}
                    openDrawer={openEdit}
                    buttonText="Salvar alterações"
                />
            </Drawer>
        </>
    );
    return <Layout tabkey="12" openkey="11" content={content} />;
};

export default Treatments;
