//React
import React, { useState, useEffect } from "react";

//Components
import Layout from "../layout/Layout";

//Library components
import { Button, Form, Input, message, Divider } from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints } = pattern;

const Profile = () => {
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [disableSubmit, setDisableSubmit] = useState(true);

    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.users.myUser);
            setData(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setName(data.name);
        setUsername(data.username);
        setEmail(data.email);
    }, [data]);

    const handleEdit = (e) => {
        e.preventDefault();
        setEdit(true);
    };

    const handleValidatePasswordEdit = (_, value) => {
        if (!value) {
            return Promise.resolve();
        }
        if (value.length < 8) {
            return Promise.reject(
                new Error("A senha deve ter pelo menos 8 caracteres!")
            );
        }
        if (!/[A-Z]/.test(value)) {
            return Promise.reject(
                new Error("A senha deve conter pelo menos uma letra maiúscula!")
            );
        }
        if (!/[a-z]/.test(value)) {
            return Promise.reject(
                new Error("A senha deve conter pelo menos uma letra minúscula!")
            );
        }
        if (!/\d/.test(value)) {
            return Promise.reject(
                new Error("A senha deve conter pelo menos um número!")
            );
        }
        if (!/[\W_]/.test(value)) {
            return Promise.reject(
                new Error(
                    "A senha deve conter pelo menos um caractere especial!"
                )
            );
        }
        return Promise.resolve();
    };

    const handleDisableSubmit = () => {
        if (
            name !== data.name ||
            username !== data.username ||
            email !== data.email ||
            currentPassword.length > 0 ||
            newPassword.length > 0 ||
            repeatPassword.length > 0
        ) {
            return setDisableSubmit(false);
        }

        setDisableSubmit(true);
    };

    useEffect(() => {
        handleDisableSubmit();
    }, [name, username, email, currentPassword, newPassword, repeatPassword]);

    const onFinish = async (values) => {
        try {
            await axios.post(endpoints.users.updateMyUser, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchData();
            setEdit(false);
            setDisableSubmit(true);
            form.resetFields([
                "current_password",
                "new_password",
                "new_password_confirmation",
            ]);
            setRepeatPassword("");
            setCurrentPassword("");
            setRepeatPassword("");
            message.success("Usuário atualizado com sucesso!");
        } catch (error) {
            if (error.response.data.message === "Senha atual inválida") {
                return message.error("Senha atual inválida");
            }
            if (error.response.data.constraint === "users_username_unique") {
                return message.error("Usuário já cadastrado!");
            }
            if (error.response.data.constraint === "users_email_unique") {
                return message.error("Email já cadastrado!");
            }

            message.error("Campo(s) inválido(s)");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const content = data.id ? (
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Insira o nome!",
                    },
                ]}
                initialValue={data.name}
            >
                <Input
                    placeholder="Nome"
                    disabled={!edit}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: "Insira o usuário!",
                    },
                ]}
                initialValue={data.username}
            >
                <Input
                    placeholder="Usuário"
                    disabled={!edit}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Insira o email!",
                    },
                ]}
                initialValue={data.email}
            >
                <Input
                    placeholder="Email"
                    disabled={!edit}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
            </Form.Item>

            {edit ? (
                <>
                    <Divider />
                    <Form.Item
                        name="current_password"
                        dependencies={[
                            "new_password",
                            "new_password_confirmation",
                        ]}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        (value === "" || value === undefined) &&
                                        (getFieldValue("new_password") === "" ||
                                            getFieldValue("new_password") ===
                                                undefined) &&
                                        (getFieldValue(
                                            "new_password_confirmation"
                                        ) === "" ||
                                            getFieldValue(
                                                "new_password_confirmation"
                                            ) === undefined)
                                    ) {
                                        return Promise.resolve();
                                    } else if (
                                        value !== "" &&
                                        getFieldValue("new_password") !== "" &&
                                        getFieldValue("new_password") !==
                                            undefined &&
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) !== "" &&
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) !== undefined
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(
                                            "Todos os campos de senha devem ser preenchidos!"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder="Senha atual"
                            onChange={(e) => {
                                setCurrentPassword(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="new_password"
                        dependencies={[
                            "current_password",
                            "new_password_confirmation",
                        ]}
                        rules={[
                            { validator: handleValidatePasswordEdit },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        (getFieldValue("current_password") ===
                                            "" ||
                                            getFieldValue(
                                                "current_password"
                                            ) === undefined) &&
                                        (value === "" || value === undefined) &&
                                        (getFieldValue(
                                            "new_password_confirmation"
                                        ) === "" ||
                                            getFieldValue(
                                                "new_password_confirmation"
                                            ) === undefined)
                                    ) {
                                        return Promise.resolve();
                                    } else if (
                                        getFieldValue("current_password") !==
                                            "" &&
                                        getFieldValue("current_password") !==
                                            undefined &&
                                        value !== "" &&
                                        value !== undefined &&
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) !== "" &&
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) !== undefined
                                    ) {
                                        if (
                                            !value ||
                                            getFieldValue(
                                                "current_password"
                                            ) !== value
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error(
                                                    "A nova senha não pode ser igual a senha atual!"
                                                )
                                            );
                                        }
                                    }

                                    return Promise.reject(
                                        new Error(
                                            "Todos os campos de senha devem ser preenchidos!"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder="Nova senha"
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="new_password_confirmation"
                        dependencies={["current_password", "new_password"]}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        (getFieldValue("current_password") ===
                                            "" ||
                                            getFieldValue(
                                                "current_password"
                                            ) === undefined) &&
                                        (getFieldValue("new_password") === "" ||
                                            getFieldValue("new_password") ===
                                                undefined) &&
                                        (value === "" || value === undefined)
                                    ) {
                                        return Promise.resolve();
                                    } else if (
                                        getFieldValue("current_password") !==
                                            "" &&
                                        getFieldValue("current_password") !==
                                            undefined &&
                                        getFieldValue("new_password") !== "" &&
                                        getFieldValue("new_password") !==
                                            undefined &&
                                        value !== "" &&
                                        value !== undefined
                                    ) {
                                        if (
                                            !value ||
                                            getFieldValue("new_password") ===
                                                value
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error(
                                                    "As senhas não correspondem!"
                                                )
                                            );
                                        }
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "Todos os campos de senha devem ser preenchidos!"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder="Repetir nova senha"
                            onChange={(e) => {
                                setRepeatPassword(e.target.value);
                            }}
                        />
                    </Form.Item>{" "}
                </>
            ) : (
                ""
            )}

            <Form.Item>
                {!edit ? (
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        onClick={(e) => {
                            handleEdit(e);
                        }}
                    >
                        Editar
                    </Button>
                ) : (
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        disabled={disableSubmit}
                    >
                        Salvar alterações
                    </Button>
                )}
            </Form.Item>
        </Form>
    ) : (
        ""
    );
    return <Layout tabkey="6" openkey="5" content={content} />;
};

export default Profile;
