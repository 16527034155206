import React, { useEffect, useRef } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const BudgetPdf = (props) => {
    const iframeRef = useRef();
    const countRender = useRef(0);

    const handleFormatCurrency = (value) => {
        return `R$ ${Number(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
        })}`;
    };

    const subtotal = props.budget.treatments.reduce((total, treatment) => {
        return total + Number(treatment.value);
    }, 0);

    const subtotalFormatted = handleFormatCurrency(subtotal);

    const documentDefinition = {
        header: function () {
            return {
                columns: [
                    {
                        text: props.account.name,
                        alignment: "left",
                        margin: [0, 10, 40, 0],
                    },
                    {
                        text: dayjs().format("DD/MM/YYYY"),
                        alignment: "right",
                        margin: [0, 10, 40, 0],
                    },
                ],
                margin: [40, 10, 0, 0],
            };
        },
        content: [
            "\n\n",
            {
                text: "Plano de tratamento",
                style: "header",
            },

            "\n\n",

            {
                table: {
                    widths: ["*", "*"],
                    body: [
                        [
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Nome: ",
                                        bold: true,
                                    },
                                    props.patient.name,
                                ],
                                columnGap: 10,
                            },
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Data de nascimento: ",
                                        bold: true,
                                    },
                                    props.patient.birthFormatted,
                                ],
                                columnGap: 10,
                            },
                        ],
                        [
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "CPF: ",
                                        bold: true,
                                    },
                                    props.patient.cpf,
                                ],
                                columnGap: 10,
                            },
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Endereço: ",
                                        bold: true,
                                    },
                                    props.patient.address,
                                ],
                                columnGap: 10,
                            },
                        ],
                    ],
                },
                layout: "noBorders",
            },

            "\n\n",

            {
                canvas: [
                    {
                        type: "line",
                        x1: 0,
                        y1: 5,
                        x2: 595 - 2 * 40,
                        y2: 5,
                        lineWidth: 1,
                    },
                ],
            },
            "\n\n",

            {
                table: {
                    widths: ["*", "*"],

                    body: [
                        [
                            { text: "Procedimentos", bold: true },
                            { text: "Valor", bold: true },
                        ],
                        ...props.budget.treatments.map((treatment) => [
                            {
                                text: treatment.name,
                            },
                            {
                                text: handleFormatCurrency(
                                    treatment.original_value
                                ),
                            },
                        ]),
                    ],
                },
                layout: "noBorders",
            },
            "\n\n",

            {
                canvas: [
                    {
                        type: "line",
                        x1: 0,
                        y1: 5,
                        x2: 595 - 2 * 40,
                        y2: 5,
                        lineWidth: 1,
                    },
                ],
            },

            "\n\n",
            {
                text: [
                    { text: "Subtotal: ", bold: true },
                    { text: subtotalFormatted },
                ],
                alignment: "left",
            },
            "\n",
            {
                text: [
                    { text: "Desconto: ", bold: true },
                    {
                        text:
                            props.budget.discount.type === "%"
                                ? `${props.budget.discount.value}${props.budget.discount.type}`
                                : handleFormatCurrency(
                                      props.budget.discount.value
                                  ),
                    },
                ],
                alignment: "left",
            },
            "\n",
            {
                text: [
                    { text: "Total: ", bold: true },
                    { text: props.budget.final_value_formatted },
                ],
                alignment: "left",
            },
            "\n\n\n\n",
            {
                text: `______________________________________________`,
                alignment: "center",
                margin: [0, 5, 0, 0],
            },
            "\n",
            {
                text: `Dr(a): ${props.userName}`,
                alignment: "center",
                margin: [0, 5, 0, 0],
            },
        ],
        footer: function (currentPage, pageCount) {
            return {
                margin: 10,
                columns: [
                    {
                        text: [
                            {
                                text:
                                    "Página " +
                                    currentPage.toString() +
                                    " de " +
                                    pageCount,
                            },
                        ],
                        alignment: "left",
                        fontSize: 9,
                        margin: [40, 0, 0, 0], // Ajuste os valores conforme necessário
                    },
                ],
            };
        },

        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                margin: [0, 0, 0, 10], // Margem inferior de 10
            },
        },
    };

    useEffect(() => {
        // if (countRender.current < 1) {
        //     countRender.current += 1;
        //     return;
        // }
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

        pdfDocGenerator.getBlob((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            iframeRef.current.src = blobUrl;
        });
    }, [documentDefinition]);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "500px", border: "none" }}
        ></iframe>
    );
};

export default BudgetPdf;
