import React, { useState, useEffect, useRef } from "react";
import { usePDFContext } from "../../context/PDFProvider";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints } = pattern;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AnamnesePdf = (props) => {
    const { setPdfBase64 } = usePDFContext();

    const [dataPdf, setDataPdf] = useState([]);
    const [documentDefinition, setDocumentDefinition] = useState([]);
    const iframeRef = useRef();
    const countRender = useRef(0);

    const docDef = {
        header: function () {
            return {
                columns: [
                    {
                        text: props.account.name,
                        alignment: "left",
                        margin: [0, 10, 40, 0],
                    },
                    {
                        text: dayjs().format("DD/MM/YYYY"),
                        alignment: "right",
                        margin: [0, 10, 40, 0],
                    },
                ],
                margin: [40, 10, 0, 0],
            };
        },
        content: [
            "\n\n",
            { text: "Ficha de Anamnese", style: "header" },

            "\n\n",

            {
                table: {
                    widths: ["*", "*"],
                    body: [
                        [
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Nome: ",
                                        bold: true,
                                    },
                                    props.patient.name,
                                ],
                                columnGap: 10,
                            },
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Data de nascimento: ",
                                        bold: true,
                                    },
                                    props.patient.birthFormatted,
                                ],
                                columnGap: 10,
                            },
                        ],
                        [
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "CPF: ",
                                        bold: true,
                                    },
                                    props.patient.cpf,
                                ],
                                columnGap: 10,
                            },
                            {
                                columns: [
                                    {
                                        width: "auto",
                                        text: "Endereço: ",
                                        bold: true,
                                    },
                                    props.patient.address,
                                ],
                                columnGap: 10,
                            },
                        ],
                    ],
                },
                layout: "noBorders",
            },

            "\n\n",
            {
                canvas: [
                    {
                        type: "line",
                        x1: 0,
                        y1: 5,
                        x2: 595 - 2 * 40,
                        y2: 5,
                        lineWidth: 1,
                    },
                ],
            },
            "\n\n",

            {
                table: {
                    widths: ["*", "*"],

                    body: [
                        [
                            { text: "Pergunta", bold: true },
                            { text: "Resposta", bold: true },
                        ],
                        ...dataPdf.map((questionAnswer, index) => [
                            {
                                text: questionAnswer.question,
                                fillColor:
                                    index % 2 === 0 ? "#F0F0F0" : "#FFFFFF",
                            },
                            {
                                text: questionAnswer.answer,
                                fillColor:
                                    index % 2 === 0 ? "#F0F0F0" : "#FFFFFF",
                            },
                        ]),
                    ],
                },
                layout: "noBorders",
            },

            "\n\n\n\n",
            {
                text: "Assino este declarando verdadeiras as informações ditas acima",
                alignment: "center",
                margin: [0, 5, 0, 0],
            },
            "\n\n",
            {
                text: "________________________________________________________",
                alignment: "center",
                margin: [0, 5, 0, 0],
            },
            "\n",
            {
                text: `Assinatura do Paciente: ${props.patient.name}`,
                alignment: "center",
                fontSize: 10,
                margin: [0, 5, 0, 0],
            },
        ],
        footer: function (currentPage, pageCount) {
            return {
                margin: 10,
                columns: [
                    {
                        text: [
                            {
                                text:
                                    "Página " +
                                    currentPage.toString() +
                                    " de " +
                                    pageCount,
                            },
                        ],
                        alignment: "left",
                        fontSize: 9,
                        margin: [40, 0, 0, 0], // Ajuste os valores conforme necessário
                    },
                ],
            };
        },

        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                margin: [0, 0, 0, 10], // Margem inferior de 10
            },
        },
    };

    const getAnamneseQuestionsAnswers = async (id) => {
        try {
            const response = await axios.get(
                endpoints.anamneses.indexQuestionsAnswers.replace("${}", id)
            );

            setDataPdf(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        getAnamneseQuestionsAnswers(props.id);
    }, [props.id]);

    useEffect(() => {
        setDocumentDefinition(docDef);
    }, [dataPdf]);

    useEffect(() => {
        if (countRender.current < 1) {
            countRender.current += 1;
            return;
        }
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

        pdfDocGenerator.getBase64((base64) => {
            setPdfBase64(base64);
            // Use o base64 conforme necessário
            const base64String = "data:application/pdf;base64," + base64;

            iframeRef.current.src = props.sign
                ? `${base64String}#toolbar=0`
                : base64String;
        });
    }, [documentDefinition]);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "500px", border: "none" }}
        ></iframe>
    );
};

export default AnamnesePdf;
