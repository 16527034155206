//React
import React, { useState, useRef } from "react";
import { usePDFContext } from "../context/PDFProvider";
import { useAuth } from "../context/AuthProvider";

//Icons
import { FaEraser } from "react-icons/fa";

//Library components
import { Button, Flex, Modal, message } from "antd";
import SignatureCanvas from "react-signature-canvas";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints } = pattern;

const ModalSignature = (props) => {
    const { isMobile } = useAuth();

    const { pdfBase64 } = usePDFContext();

    const [displaySignButton, setDisplaySignButton] = useState(true);
    const [disableSignButton, setDisableSignButton] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const canvasRef = useRef();

    const showModal = () => {
        setIsModalOpen(true);
        setDisplaySignButton(false);
    };

    const handleCancel = () => {
        clearSignature();
        setIsModalOpen(false);
        setDisplaySignButton(true);
    };

    const handleDisableSignButton = () => {
        setDisableSignButton(canvasRef.current.isEmpty());
    };

    const clearSignature = () => {
        canvasRef.current.clear();
        setDisableSignButton(true);
    };

    const gerarPDFComAssinatura = async () => {
        try {
            const assinaturaBase64 = canvasRef.current.toDataURL();

            const response = await axios.post(endpoints.pdf.signPdf, {
                pdfBase64,
                assinaturaBase64,
                entity: props.entity,
            });

            message.success("Documento assinado com sucesso!");

            handleCancel();
            setLoading(false);
            props.fetch();
            props.close();
        } catch (error) {
            console.error("Erro ao realizar assinatura:", error);
        }
    };

    return (
        <>
            <Button
                type="primary"
                shape="round"
                onClick={showModal}
                style={{
                    fontWeight: "bold",
                    width: !isMobile ? "520px" : "300px",
                    position: "absolute",
                    bottom: 40,
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: displaySignButton ? "block" : "none",
                }}
            >
                Assinar agora
            </Button>

            <Modal
                title={
                    <Flex align="center" justify="space-between">
                        Assinatura
                        <Button
                            icon={<FaEraser />}
                            shape="round"
                            onClick={clearSignature}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            Limpar
                        </Button>
                    </Flex>
                }
                open={isModalOpen}
                className="modalDocumentSignCanvas"
                onCancel={handleCancel}
                centered={true}
                footer={(_, {}) => (
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            setLoading(true);
                            gerarPDFComAssinatura();
                        }}
                        disabled={disableSignButton}
                        loading={loading}
                        style={{ fontWeight: "bold", width: "100%" }}
                    >
                        Assinar
                    </Button>
                )}
            >
                <SignatureCanvas
                    ref={canvasRef}
                    canvasProps={{
                        style: {
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                        },
                        width: !isMobile ? 468 : 300,
                        height: 200,
                    }}
                    onEnd={handleDisableSignButton}
                    backgroundColor="#ffffff"
                    penColor="black"
                />
            </Modal>
        </>
    );
};

export default ModalSignature;
