//React
import React, { useState, useEffect, useRef } from "react";

//Context
import { PDFProvider } from "../../context/PDFProvider";

//Components
import FormBudget from "../FormBudget";
import BudgetPdf from "../pdf/BudgetPdf";

//Icons
import {
    FaFileDownload,
    FaFileSignature,
    FaCheck,
    FaEllipsisH,
    FaEdit,
    FaPrint,
} from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";

//Library components
import {
    Flex,
    Table,
    Modal,
    List,
    Form,
    message,
    Tooltip,
    Button,
    Dropdown,
    Drawer,
    Popconfirm,
    Descriptions,
} from "antd";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;

const PatientBudget = (props) => {
    const [loadingBudgets, setLoadingBudgets] = useState(true);
    const [dataBudget, setDataBudget] = useState([]);
    const [dataPatientBudgets, setDataPatientBudgets] = useState([]);
    const [dataUpdateTreatments, setDataUpdateTreatments] = useState([]);
    const countRender = useRef(0);

    const [isModalPrintBudgetOpen, setIsModalPrintBudgetOpen] = useState(false);

    const [idBudget, setIdBudget] = useState(null);
    const [openBudget, setOpenBudget] = useState(false);
    const [openBudgetEdit, setOpenBudgetEdit] = useState(false);
    const [formBudget] = Form.useForm();
    const [formBudgetEdit] = Form.useForm();

    const fetchDataBudgets = async () => {
        try {
            const response = await axios.get(
                endpoints.budgets.index.replace("${}", props.patientId)
            );
            setDataPatientBudgets(response.data);
            setLoadingBudgets(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };
    const fetchDataUpdateTreatmentsBudget = async (id) => {
        try {
            const response = await axios.get(
                endpoints.budgets.indexUpdateTreatments.replace("${}", id)
            );

            setDataUpdateTreatments(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        // if (countRender.current < 1) {
        //     countRender.current += 1;
        //     return;
        // }
        if (props.isModalOpen === false) {
            return;
        }

        fetchDataBudgets();
    }, [props.isModalOpen]);

    useEffect(() => {
        props.fetchDataActiveTreatments();
    }, [openBudget]);

    const showModalPrintBudget = () => {
        setIsModalPrintBudgetOpen(true);
    };
    const handleCancelPrintBudget = () => {
        setIsModalPrintBudgetOpen(false);
    };

    const showPrintBudget = async (id) => {
        const budget = dataPatientBudgets.find((budget) => budget.id === id);

        setDataBudget(budget);
        showModalPrintBudget();
    };

    const onCloseBudget = () => {
        setOpenBudget(false);
        formBudget.resetFields();
    };

    const handleApproveBudget = async (id, status) => {
        try {
            await axios.post(
                endpoints.budgets.approve.replace("${}", id),
                { approved: status },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            message.success(
                status
                    ? "Orçamento aprovado com sucesso!"
                    : "Orçamento atualizado com sucesso!"
            );

            fetchDataBudgets();
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    };

    const showDrawerBudgetEdit = (id) => {
        const budget = {
            ...dataPatientBudgets.find((budget) => budget.id === id),
        };

        setDataBudget(budget);
        formBudgetEdit.setFieldsValue(budget);
        fetchDataUpdateTreatmentsBudget(id);
        setOpenBudgetEdit(true);
    };

    const onCloseBudgetEdit = () => {
        setOpenBudgetEdit(false);
    };

    // const showModalSign = () => {
    //     setIsModalSignOpen(true);
    // };
    // const handleCancelSign = () => {
    //     setIsModalSignOpen(false);
    // };

    const onFinishBudget = async (values) => {
        const treatmentsIdsValues = props.dataActiveTreatments
            .filter((treatment) => {
                return values.treatments_ids.includes(treatment.id);
            })
            .map((treatment) => ({
                id: treatment.id,
                value: treatment.value,
            }));
        try {
            await axios.post(
                endpoints.budgets.create.replace("${}", props.patientId),
                {
                    ...values,
                    patient_id: props.patientId,
                    treatments_ids: treatmentsIdsValues,
                },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            onCloseBudget();

            message.success("Orçamento cadastrado com sucesso!");

            fetchDataBudgets();
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishBudgetEdit = async (values) => {
        const treatmentsIdsValues = dataUpdateTreatments
            .filter((treatment) => {
                return values.treatments_ids.includes(treatment.id);
            })
            .map((treatment) => ({
                id: treatment.id,
                value: treatment.value,
            }));
        try {
            await axios.post(
                endpoints.budgets.update.replace("${}", idBudget),
                {
                    ...values,
                    treatments_ids: treatmentsIdsValues,
                },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchDataBudgets();
            onCloseBudgetEdit();
            message.success("Categoria atualizado com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const subitemsBudgets = (id) => [
        props.isMobile
            ? {
                  key: "1",
                  label: (
                      <Button
                          type="link"
                          style={{
                              display: "flex",
                              alignItems: "center",
                          }}
                          onClick={() => {
                              handleApproveBudget(id, true);
                          }}
                          icon={<FaCheck />}
                      >
                          Aprovar
                      </Button>
                  ),
              }
            : null,
        {
            key: "2",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaEdit />}
                    onClick={() => {
                        setIdBudget(id);
                        showDrawerBudgetEdit(id);
                    }}
                >
                    Editar
                </Button>
            ),
        },
        {
            key: "3",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaPrint />}
                    onClick={() => {
                        showPrintBudget(id);
                    }}
                >
                    Imprimir
                </Button>
            ),
        },
    ];

    const columnsBudgets = !props.isMobile
        ? [
              {
                  title: "Tratamentos",
                  dataIndex: "treatments",
                  key: "treatments",
                  width: "40%",
                  render: (_, { treatments }) => (
                      <>
                          <List
                              size="small"
                              bordered
                              dataSource={treatments}
                              renderItem={(item) => (
                                  <List.Item>{item.name}</List.Item>
                              )}
                          />
                      </>
                  ),
              },
              {
                  title: "Valor",
                  dataIndex: "final_value_formatted",
                  key: "final_value_formatted",
              },
              {
                  title: "Criado",
                  dataIndex: "created_at_formatted",
                  key: "created_at_formatted",
              },
              {
                  title: "Atualizado",
                  dataIndex: "updated_at_formatted",
                  key: "updated_at_formatted",
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  width: "140px",
                  render: (_, { id, approved }) =>
                      approved ? (
                          <Flex>
                              <Tooltip title="Desfazer">
                                  <Popconfirm
                                      title="Desfazer aprovar"
                                      description="Você tem certeza?"
                                      onConfirm={() =>
                                          handleApproveBudget(id, false)
                                      }
                                      okText="Sim"
                                      cancelText="Não"
                                  >
                                      <Button
                                          danger
                                          style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                          }}
                                      >
                                          <FaArrowRotateLeft />
                                      </Button>
                                  </Popconfirm>
                              </Tooltip>
                          </Flex>
                      ) : (
                          <Flex gap="small">
                              <Tooltip title="Aprovar">
                                  <Button
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                      onClick={() => {
                                          handleApproveBudget(id, true);
                                      }}
                                  >
                                      <FaCheck />
                                  </Button>
                              </Tooltip>
                              <Dropdown
                                  menu={{ items: subitemsBudgets(id) }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <FaEllipsisH />
                                  </Button>
                              </Dropdown>
                          </Flex>
                      ),
              },
          ]
        : [
              {
                  title: "Tratamentos",
                  dataIndex: "treatments",
                  key: "treatments",
                  render: (_, { treatments }) => (
                      <>
                          <List
                              size="small"
                              bordered
                              dataSource={treatments}
                              renderItem={(item) => (
                                  <List.Item>{item.name}</List.Item>
                              )}
                          />
                      </>
                  ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id, approved }) =>
                      approved ? (
                          <Flex>
                              <Tooltip title="Desfazer">
                                  <Popconfirm
                                      title="Desfazer aprovar"
                                      description="Você tem certeza?"
                                      onConfirm={() =>
                                          handleApproveBudget(id, false)
                                      }
                                      okText="Sim"
                                      cancelText="Não"
                                  >
                                      <Button
                                          danger
                                          style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                          }}
                                      >
                                          <FaArrowRotateLeft />
                                      </Button>
                                  </Popconfirm>
                              </Tooltip>
                          </Flex>
                      ) : (
                          <Flex gap="small">
                              <Dropdown
                                  menu={{
                                      items: subitemsBudgets(id, approved),
                                  }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button icon={<FaEllipsisH />} />
                              </Dropdown>
                          </Flex>
                      ),
              },
          ];

    const expandedRowRenderBudget = (record) => {
        const items = !props.isMobile
            ? []
            : [
                  {
                      label: "Valor",
                      children: record.final_value_formatted,
                  },
                  {
                      label: "Criado",
                      children: record.created_at_formatted,
                  },
                  {
                      label: "Atualizado",
                      children: record.updated_at_formatted,
                  },
              ];

        return <Descriptions items={items} />;
    };

    return (
        <>
            <Drawer
                title="+ Orçamento"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onCloseBudget}
                open={openBudget}
                key="createBudgets"
            >
                <FormBudget
                    name="createBudgets"
                    form={formBudget}
                    onFinish={onFinishBudget}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.budgets.single}
                    selectSearchProps={{
                        data: {
                            treatments: props.dataActiveTreatments,
                        },
                        endpoint: {
                            treatments: endpoints.treatments.indexCreate,
                        },
                    }}
                    originalTreatments={[]}
                    discountValue={0}
                    openDrawer={openBudget}
                    buttonText="Gerar orçamento"
                />
            </Drawer>

            <Drawer
                title="Editar orçamento"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onCloseBudgetEdit}
                open={openBudgetEdit}
                key="editBudgets"
            >
                <FormBudget
                    name="editBudgets"
                    form={formBudgetEdit}
                    onFinish={onFinishBudgetEdit}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.budgets.single}
                    selectSearchProps={{
                        data: {
                            treatments: dataUpdateTreatments,
                        },
                        endpoint: {
                            treatments:
                                endpoints.budgets.indexUpdateTreatments.replace(
                                    "${}",
                                    props.patientId
                                ),
                        },
                    }}
                    originalTreatments={dataBudget.treatments}
                    discountValue={dataBudget.discount?.value}
                    openDrawer={openBudgetEdit}
                    buttonText="Salvar alterações"
                />
            </Drawer>

            <Modal
                title={
                    <Flex
                        align="center"
                        justify="space-between"
                        className="modal-header-title-button"
                    >
                        <div className="modalTitle">Orçamento</div>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenBudget(true);
                            }}
                        >
                            + Cadastrar
                        </Button>
                    </Flex>
                }
                open={props.isModalOpen}
                onCancel={() => props.setIsModalOpen(false)}
                centered={true}
                width={1000}
                footer={(_, {}) => <></>}
            >
                <Table
                    rowKey="id"
                    columns={columnsBudgets}
                    dataSource={dataPatientBudgets}
                    locale={localeTable}
                    loading={loadingBudgets}
                    expandable={
                        !props.isMobile
                            ? false
                            : {
                                  expandedRowRender: expandedRowRenderBudget,
                              }
                    }
                    pagination={{
                        defaultPageSize: 5,
                    }}
                />
            </Modal>
            <Modal
                title="Imprimir"
                open={isModalPrintBudgetOpen}
                onCancel={handleCancelPrintBudget}
                centered={true}
                width={700}
                footer={(_, {}) => <></>}
            >
                <BudgetPdf
                    userName={props.userName}
                    patient={props.handleGetPatient(props.patientId)}
                    budget={dataBudget}
                    account={props.account}
                />
            </Modal>
        </>
    );
};

export default PatientBudget;
