//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import FormComponent from "../components/FormComponent";

//Icons
import { FaEdit } from "react-icons/fa";

//Library components
import {
    Flex,
    message,
    Table,
    Drawer,
    Form,
    Input,
    Button,
    Descriptions,
    Switch,
} from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;
const { Search } = Input;
const Users = () => {
    const { isMobile } = useAuth();

    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [id, setId] = useState(null);

    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.users.index);
            setData(response.data);
            setOriginalData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const onSearch = (value) => {
        const dataSearch = originalData.filter((users) => {
            return (
                users.name.includes(value) ||
                users.username.includes(value) ||
                users.email.includes(value)
            );
        });

        setData(dataSearch);
    };

    const onFinish = async (values) => {
        try {
            await axios.post(endpoints.users.create, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchData();
            onClose();
            message.success("Usuário cadastrado com sucesso!");
        } catch (error) {
            if (error.response.data.constraint === "users_username_unique") {
                return message.error("Usuário já cadastrado! Tente novamente.");
            }
            if (error.response.data.constraint === "users_email_unique") {
                return message.error("Email já cadastrado! Tente novamente.");
            }
            if (error.response.data.messages.errors) {
                return message.error(
                    error.response.data.messages.errors[0].message
                );
            }
            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
    };

    const showDrawerEdit = (id) => {
        const user = {
            ...data.find((users) => users.id === id),
        };

        formEdit.setFieldsValue(user);
        setOpenEdit(true);
    };

    const onFinishEdit = async (values, id_only) => {
        try {
            await axios.post(
                endpoints.users.update.replace("${}", id_only ? id_only : id),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            onCloseEdit();
            message.success("Usuário atualizado com sucesso!");
        } catch (error) {
            if (error.response.data.constraint === "users_username_unique") {
                return message.error("Usuário já cadastrado! Tente novamente.");
            }
            if (error.response.data.constraint === "users_email_unique") {
                return message.error("Email já cadastrado! Tente novamente.");
            }
            if (error.response.data.message) {
                return message.error(error.response.data.message);
            }

            message.error("Campo(s) inválido(s)!");
        }
    };

    const columns = !isMobile
        ? [
              {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
              },
              {
                  title: "Usuário",
                  dataIndex: "username",
                  key: "username",
              },
              {
                  title: "Email",
                  dataIndex: "email",
                  key: "email",
              },
              {
                  title: "Perfil",
                  dataIndex: "role_formatted",
                  key: "role_formatted",
              },
              {
                  title: "Ativo",
                  dataIndex: "active",
                  key: "active",
                  render: (_, { id, active }) => (
                      <>
                          <Switch
                              checked={active}
                              onChange={(value) => {
                                  onFinishEdit({ active: value }, id);
                              }}
                          />
                      </>
                  ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawerEdit(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ]
        : [
              {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawerEdit(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ];

    const expandedRowRender = (record) => {
        const items = !isMobile
            ? []
            : [
                  {
                      label: "Usuário",
                      children: record.username,
                  },
                  {
                      label: "Email",
                      children: record.email,
                  },
                  {
                      label: "Perfil",
                      children: record.role_formatted,
                  },
              ];

        return <Descriptions items={items} />;
    };

    const content = (
        <>
            <Flex
                className="table-header-button-search"
                justify="space-between"
                gap="middle"
                style={{ marginBottom: "24px" }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    + Cadastrar
                </Button>
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                    }}
                />
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                expandable={!isMobile ? false : { expandedRowRender }}
                rowKey={(record) => record.id}
            />
            <Drawer
                title="+ Cadastrar usuário"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onClose}
                open={open}
                key="createUser"
            >
                <FormComponent
                    name="createUser"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.users.create}
                    openDrawer={open}
                    buttonText="Cadastrar"
                />
            </Drawer>
            <Drawer
                title="Editar usuário"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onCloseEdit}
                open={openEdit}
                key="editUser"
            >
                <FormComponent
                    name="editUser"
                    form={formEdit}
                    onFinish={onFinishEdit}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.users.edit}
                    openDrawer={openEdit}
                    buttonText="Salvar alterações"
                />
            </Drawer>
        </>
    );
    return <Layout tabkey="20" openkey="5" content={content} />;
};

export default Users;
