//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import FormComponent from "../components/FormComponent";

//Icons
import { FaEdit } from "react-icons/fa";

//Library components
import {
    Flex,
    message,
    Table,
    Drawer,
    Form,
    Input,
    Button,
    ColorPicker,
    Switch,
} from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;
const { Search } = Input;

const Categories = () => {
    const { isMobile } = useAuth();

    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [id, setId] = useState(null);

    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.categories.index);
            setData(response.data);
            setOriginalData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const onSearch = (value) => {
        const dataSearch = originalData.filter((categories) => {
            return (
                categories.name.includes(value) ||
                categories.color.includes(value)
            );
        });

        setData(dataSearch);
    };

    const onFinish = async (values) => {
        try {
            await axios.post(endpoints.categories.create, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchData();
            onClose();
            message.success("Categoria cadastrada com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
    };

    const showDrawerEdit = (id) => {
        const categories = {
            ...data.find((categories) => categories.id === id),
        };

        formEdit.setFieldsValue(categories);
        setOpenEdit(true);
    };

    const onFinishEdit = async (values, id_only) => {
        try {
            await axios.post(
                endpoints.categories.update.replace(
                    "${}",
                    id_only ? id_only : id
                ),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            onCloseEdit();
            message.success("Categoria atualizada com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const columns = [
        {
            title: "Categoria",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cor",
            dataIndex: "color",
            key: "color",
            render: (_, { colorHex, colorName }) => (
                <ColorPicker
                    value={colorHex}
                    showText={!isMobile ? () => <>{colorName}</> : false}
                    open={false}
                    style={{ cursor: "inherit" }}
                />
            ),
        },
        {
            title: "Ativo",
            dataIndex: "active",
            key: "active",
            render: (_, { id, active }) => (
                <>
                    <Switch
                        checked={active}
                        onChange={(value) => {
                            onFinishEdit({ active: value }, id);
                        }}
                    />
                </>
            ),
        },
        {
            title: "Ações",
            dataIndex: "id",
            key: "id",
            render: (_, { id }) => (
                <Flex>
                    <Button
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                        }}
                        onClick={() => {
                            showDrawerEdit(id);
                            setId(id);
                        }}
                    >
                        <FaEdit />
                    </Button>
                </Flex>
            ),
        },
    ];

    const content = (
        <>
            <Flex
                className="table-header-button-search"
                justify="space-between"
                gap="middle"
                style={{ marginBottom: "24px" }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    + Cadastrar
                </Button>
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                    }}
                />
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                rowKey={(record) => record.id}
            />
            <Drawer
                title="+ Cadastrar categoria"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onClose}
                open={open}
                key="createCategory"
            >
                <FormComponent
                    name="createCategory"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.categories.single}
                    openDrawer={open}
                    buttonText="Cadastrar"
                />
            </Drawer>
            <Drawer
                title="Editar categoria"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onCloseEdit}
                open={openEdit}
                key="editCategory"
            >
                <FormComponent
                    name="editCategory"
                    form={formEdit}
                    onFinish={onFinishEdit}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.categories.single}
                    openDrawer={openEdit}
                    buttonText="Salvar alterações"
                />
            </Drawer>
        </>
    );
    return <Layout tabkey="15" openkey="11" content={content} />;
};

export default Categories;
