import React, { createContext, useState, useContext } from "react";

const PDFContext = createContext();

export const PDFProvider = ({ children }) => {
    const [pdfBase64, setPdfBase64] = useState(null);

    return (
        <PDFContext.Provider value={{ pdfBase64, setPdfBase64 }}>
            {children}
        </PDFContext.Provider>
    );
};

export const usePDFContext = () => useContext(PDFContext);
