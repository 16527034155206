//React
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

//Context
import { PDFProvider } from "../context/PDFProvider";

//Components
import Layout from "../layout/Layout";

const Teste = () => {
    const canvasRef = useRef();

    const teste = () => {
        console.log(canvasRef.current.toDataURL());
    };
    const content = (
        <>
            <PDFProvider>
                <SignatureCanvas
                    ref={canvasRef}
                    canvasProps={{
                        style: {
                            border: "1px solid #000",
                            width: "100%",
                            height: "400px",
                        },
                    }}
                    onEnd={teste}
                    backgroundColor="#ffffff"
                    penColor="black"
                />
            </PDFProvider>
        </>
    );
    return <Layout tabkey="12" openkey="11" content={content} />;
};

export default Teste;
