import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // para o tema snow

const TextEditor = (props) => {
    const [quillRender, setQuillRender] = useState(false);
    useEffect(() => {
        setQuillRender(true);
    }, []);

    const handleOnChange = (value) => {
        if (quillRender) {
            props.form.setFieldsValue({
                [props.formItemName]: value,
            });
        }
    };

    const modules = {
        toolbar: [
            // Adicione aqui os itens que você deseja manter
            { header: [1, 2, false] }, // Heading
            "bold",
            "italic",
            "underline",
            "strike",
            { list: "ordered" },
            { list: "bullet" },
        ],
    };

    const modulesReadOnly = {
        toolbar: false,
    };

    return (
        <ReactQuill
            value={props.value}
            onChange={props.readOnly ? () => {} : handleOnChange}
            modules={props.readOnly ? modulesReadOnly : modules}
            readOnly={props.readOnly}
        />
    );
};

export default TextEditor;
