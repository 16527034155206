//React
import React, { useState, useEffect } from "react";

//Library components
import { Button, Form, Input, Radio, Spin } from "antd";

import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints } = pattern;

const FormAnamnese = (props) => {
    const [formAnamnese, setFormAnamnese] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchFormAnamnese = async () => {
        try {
            const response = await axios.get(
                endpoints.anamneseQuestions.indexActive
            );
            setFormAnamnese(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchFormAnamneseEdit = async () => {
        try {
            const response = await axios.get(
                endpoints.anamneses.indexQuestionsEdit.replace(
                    "${}",
                    props.idAnamnese
                )
            );
            setLoading(false);
            setFormAnamnese(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        if (props.idAnamnese && props.openDrawer) {
            fetchFormAnamneseEdit();
            return;
        }
        if (props.openDrawer) {
            fetchFormAnamnese();
        }
        if (!props.openDrawer) {
            setLoading(true);
        }
    }, [props.openDrawer]);

    const SimNaoInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            resposta: value.resposta,
            texto: value.texto,
        });

        const triggerChange = (changedValue) => {
            onChange?.({ ...data, ...changedValue });
        };

        const onRespostaChange = (e) => {
            setData({ ...data, resposta: e.target.value });
            triggerChange({ resposta: e.target.value });
        };

        return (
            <Radio.Group value={data.resposta} onChange={onRespostaChange}>
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
            </Radio.Group>
        );
    };

    const SimNaoTextoInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            resposta: value.resposta,
            texto: value.texto,
        });

        const triggerChange = (changedValue) => {
            onChange?.({ ...data, ...changedValue });
        };

        const onRespostaChange = (e) => {
            setData({ ...data, resposta: e.target.value });
            triggerChange({ resposta: e.target.value });
        };

        const onTextoChange = (e) => {
            setData({ ...data, texto: e.target.value });
            triggerChange({ texto: e.target.value });
        };

        return (
            <>
                <Radio.Group value={data.resposta} onChange={onRespostaChange}>
                    <Radio value="Sim">Sim</Radio>
                    <Radio value="Não">Não</Radio>
                </Radio.Group>
                <Input
                    value={data.texto}
                    onChange={onTextoChange}
                    placeholder="Digite aqui..."
                />
            </>
        );
    };

    const TextoInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            resposta: value.resposta,
            texto: value.texto,
        });

        const triggerChange = (changedValue) => {
            onChange?.({ ...data, ...changedValue });
        };

        const onTextoChange = (e) => {
            setData({ ...data, texto: e.target.value });
            triggerChange({ texto: e.target.value });
        };

        return (
            <Input
                value={data.texto}
                onChange={onTextoChange}
                placeholder="Digite aqui..."
            />
        );
    };

    const handleRenderFormItens = (answer_type) => {
        switch (answer_type) {
            case 1:
                return <SimNaoInput />;
            case 2:
                return <SimNaoTextoInput />;
            case 3:
                return <TextoInput />;
        }
    };

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
        >
            <Spin
                spinning={loading}
                style={{ margin: "0 auto", width: "100%" }}
            />
            {formAnamnese.map((form) => (
                <React.Fragment key={form.id}>
                    <Form.Item
                        name={form.id}
                        label={form.name}
                        rules={[
                            {
                                required: true,
                                message: "Insira uma resposta!",
                            },
                        ]}
                    >
                        {handleRenderFormItens(form.answer_type)}
                    </Form.Item>
                </React.Fragment>
            ))}

            {!loading ? (
                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        loading={props.loading}
                    >
                        {props.buttonText}
                    </Button>
                </Form.Item>
            ) : (
                ""
            )}
        </Form>
    );
};
export default FormAnamnese;
