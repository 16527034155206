//React
import React, { useState, useEffect, useRef } from "react";

//Components
import SelectSearch from "./SelectSearch";

//Library components
import { Button, Form, Select, InputNumber, Flex, List, Statistic } from "antd";
import IntlCurrencyInput from "react-intl-currency-input";

const FormBudget = (props) => {
    const [selectedTreatment, setSelectedTreatment] = useState(null);
    const [finalValue, setFinalValue] = useState();

    const [selectSearchValue, setSelectSearchValue] = useState();
    const [treatmentValue, setTreatmentValue] = useState(0);
    const [typeDiscount, setTypeDiscount] = useState("%");
    const [discountValue, setDiscountValue] = useState(0);

    const getTreatmentsSumValue = (changedValue, allValues) => {
        const treatmentId = allValues["treatment_id"];
        const discountVal = allValues["discount"].value;

        if (treatmentId) {
            const treatment = props.selectSearchProps.data.treatments.find(
                (treatment) => treatment.id === treatmentId
            );

            const treatmentFormatted = {
                ...treatment,
                original_value: treatment.value,
            };

            setSelectedTreatment(treatmentFormatted);

            setTreatmentValue(treatmentFormatted.original_value);
        }

        if (discountVal !== undefined) {
            setDiscountValue(discountVal);
        }
    };

    const handleCurrencyInput = (event, value, maskedValue) => {
        props.form.setFieldsValue({
            ["final_value"]: value,
        });
    };

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(() => {
        if (!props.openDrawer) {
            setTypeDiscount("%");
            setTreatmentValue(0);
            setDiscountValue(0);
        }

        if (props.discount.value) {
            setDiscountValue(props.discount.value);
            setTypeDiscount(props.discount.type);
            setTreatmentValue(props.treatmentValue);
        }
    }, [props.openDrawer]);

    useEffect(() => {
        if (typeDiscount === "R$") {
            const finalValue = treatmentValue - discountValue;
            props.form.setFieldValue("final_value", finalValue);
            setFinalValue(finalValue);
        }
        if (typeDiscount === "%") {
            const finalValue =
                treatmentValue - (discountValue / 100) * treatmentValue;

            props.form.setFieldValue("final_value", finalValue);
            setFinalValue(finalValue);
        }
    }, [selectedTreatment, treatmentValue, typeDiscount, discountValue]);

    const SelectBeforeInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            value: value.value,
            type: value.type,
        });

        const triggerChange = (changedValue) => {
            onChange({ ...data, ...changedValue });
        };

        const onTypeChange = (val) => {
            setData({ type: val, value: 0 });
            setDiscountValue(0);
            setTypeDiscount(val);
            triggerChange({ value: 0, type: val });
        };

        const onValueChange = (val) => {
            setData({ ...data, value: val });
            triggerChange({ value: val });
        };

        return (
            <Flex gap="small">
                <Select
                    defaultValue="%"
                    value={data.type}
                    onChange={onTypeChange}
                    options={[
                        {
                            value: "%",
                            label: "%",
                        },
                        {
                            value: "R$",
                            label: "R$",
                        },
                    ]}
                />
                <InputNumber
                    style={{
                        width: "100%",
                    }}
                    onChange={onValueChange}
                    value={data.value}
                    min={0}
                    max={
                        typeDiscount === "%"
                            ? selectedTreatment
                                ? 100
                                : props.discount.value
                                ? 100
                                : 0
                            : treatmentValue
                    }
                    defaultValue={0}
                />
            </Flex>
        );
    };

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "searchInput":
                return (
                    <SelectSearch
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        value={selectSearchValue}
                        setValue={setSelectSearchValue}
                        initialData={
                            props.selectSearchProps.data[form.formSelectEntity]
                        }
                        endpoint={props.selectSearchProps.endpoint[
                            form.formSelectEntity
                        ].replace("${}", [
                            props.form.getFieldValue(form.formSelectMain),
                        ])}
                        onChange={(value) => {
                            props.form.setFieldsValue({
                                [form.formItemName]: value,
                            });
                        }}
                    />
                );
            case "selectBeforeInput":
                return <SelectBeforeInput />;

            case "inputCurrency":
                return (
                    <IntlCurrencyInput
                        id="currencyInput"
                        currency="BRL"
                        max={10000000}
                        onChange={handleCurrencyInput}
                        config={currencyConfig}
                    />
                );
        }
    };

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
            onValuesChange={getTreatmentsSumValue}
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        label={form.formItemLabel}
                        rules={form.formItemRules}
                        initialValue={form.formItemInitialValue}
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                </React.Fragment>
            ))}

            <Form.Item
                name="final_value"
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{ display: "none" }}
            >
                <InputNumber value={finalValue} disabled />
            </Form.Item>

            <Statistic
                title="Valor final:"
                value={`R$ ${Number(finalValue).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                })}`}
            />
            <br />
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {props.buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};
export default FormBudget;
