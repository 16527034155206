//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";

//Library components
import { Flex, Table, Input, Button } from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, localeTable } = pattern;
const { Search } = Input;

const Logs = () => {
    const { isMobile } = useAuth();

    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.logs.index);
            setData(response.data);
            setOriginalData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onSearch = (value) => {
        const dataSearch = originalData.filter((logs) => {
            return (
                logs.entity.includes(value) ||
                logs.action.includes(value) ||
                logs.user_email.includes(value)
            );
        });

        setData(dataSearch);
    };

    const columns = [
        {
            title: "Entidade",
            dataIndex: "entity",
            key: "entity",
        },
        {
            title: "Ação",
            dataIndex: "action",
            key: "action",
        },
        {
            title: "Usuário",
            dataIndex: "user_email",
            key: "user_email",
        },
        {
            title: "Data",
            dataIndex: "created_at_formatted",
            key: "created_at_formatted",
        },
    ];

    const content = (
        <>
            <Flex
                className="table-header-button-search"
                justify="end"
                gap="middle"
                style={{ marginBottom: "24px" }}
            >
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                    }}
                />
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                rowKey={(record) => record.id}
            />
        </>
    );
    return <Layout tabkey="60" openkey="5" content={content} />;
};

export default Logs;
